import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

const Index = lazy(() => import('pages/Index'));
const Home = lazy(() => import('pages/Home'));
const Academy = lazy(() => import('pages/Academy'));
const Coach = lazy(() => import('pages/Coach'));
const Order = lazy(() => import('pages/Order'));
const Imprint = lazy(() => import('pages/Imprint'));
const Privacy = lazy(() => import('pages/Privacy'));

function Router() {
  return (
    <Suspense fallback={<span>Loading...</span>}>
      <BrowserRouter>
        <Switch>
		  <Route exact path="/" component={Index} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/academy" component={Academy} />
          <Route exact path="/coach/:key" component={Coach} />
          <Route exact path="/order" component={Order} />
          <Route exact path="/imprint" component={Imprint} />
          <Route exact path="/privacy-policy" component={Privacy} />
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
}

export default Router;
