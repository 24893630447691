import React from 'react';
import Router from './Router';

import i18next from 'i18next'
import { useTranslation, initReactI18next } from "react-i18next";


function App() {
  const { t } = useTranslation();

  return <Router />;
}

export default App;
